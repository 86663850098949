import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import useToggle from '@ui/hooks/useToggle';
import { detectCloseActionRequired } from '@ui-v2/components/DateRangePicker/utils';
import useIsHandheld from '@utils/hooks/useIsHandheld';
import { Item, SearchQueryParams } from '@web/types/searchWidgetTypes';
import { transformUTMParam } from '@web/utils/offerUtils';
import { constructStationQueryParameters } from '@web/utils/search/searchWidgetUtils';
import { constructSearchResultQueryPushParams } from '@web/utils/searchUtils';
import useGetStations from './hooks/useGetStations';
import usePartnerRouter from './hooks/usePartnerRouter';
import usePartnerPassengersConfig from './hooks/usePassengersConfig';
import useSearchQueryParams from './hooks/useSearchQueryParams';
import useSearchQueryParamsWithLocalStorage from './hooks/useSearchQueryParamWithLocalStorage';
import { useSettings } from './SettingsContext';
import {
  arrangeStationsToListNodes,
  findStationListNodeBySearchQuery,
  getStationCodes,
} from './utils/searchWidgetContextUtils';

export type Trip = 'round-trip' | 'one-way';

export type SearchState =
  | 'idle'
  | 'selecting-take-off-station'
  | 'selecting-destination-station'
  | 'selecting-departure-date'
  | 'selecting-arrival-date'
  | 'selecting-passengers'
  | 'routing';

export type Station = {
  code: string;
  isSelectable: boolean;
  rank: number;
  subItems: Station[];
  transportType?: string;
  value: string;
};

export type StationListNode = Station & {
  country: string;
  parentCode: string | null;
};

export type Passengers = Record<string, number[]>;

export type Stations = {
  destination: StationListNode | null;
  origin: StationListNode | null;
};

export type SearchWidgetProviderState = {
  activeTrip: Trip;
  dateRange: DateRange;
  destinationInputRef: React.RefObject<HTMLInputElement> | null;
  destinationListNodes: StationListNode[];
  destinationStations: Item[];
  handleDestinationSelection: (
    item: StationListNode | null | undefined,
  ) => void;
  handleOriginSelection: (item: StationListNode | null | undefined) => void;
  handleSearchSubmit: (e: React.FormEvent) => void;
  handleSetDateRange: (range: DateRange) => void;
  handleSetPassengers: (passengers: Passengers) => void;
  handleSetSearchState: (searchState: SearchState) => void;
  handleSetTrip: (trip: Trip) => void;
  handleSwitchStations: () => void;
  isCollapsedSearchOpen: boolean;
  isDestinationStationsLoading: boolean;
  isMobile: boolean | null;
  isOriginStationsLoading: boolean;
  originInputRef: React.RefObject<HTMLInputElement> | null;
  originListNodes: StationListNode[];
  originStations: Item[];
  passengers: Passengers;
  searchState: SearchState;
  stations: Stations;
  toggleCollapsedSearch: () => void;
};

const context = createContext<SearchWidgetProviderState>({
  activeTrip: 'round-trip',
  handleSetTrip: () => {},
  searchState: 'idle',
  handleSetSearchState: () => {},
  passengers: {},
  handleSetPassengers: () => {},
  handleSearchSubmit: () => {},
  stations: { destination: null, origin: null },
  dateRange: { from: null, to: null },
  handleSetDateRange: () => {},
  handleDestinationSelection: () => {},
  handleSwitchStations: () => {},
  handleOriginSelection: () => {},
  originListNodes: [],
  destinationListNodes: [],
  isMobile: false,
  destinationInputRef: null,
  originInputRef: null,
  isDestinationStationsLoading: false,
  isOriginStationsLoading: false,
  isCollapsedSearchOpen: false,
  destinationStations: [],
  originStations: [],
  toggleCollapsedSearch: () => {},
});

export type SearchWidgetProviderProps = {
  children?: React.ReactNode;
  config: {
    shouldUseLocalStorage: boolean;
  };
};

export const SearchWidgetProvider = ({
  children,
  config: { shouldUseLocalStorage },
}: SearchWidgetProviderProps) => {
  const { passengerRules } = usePartnerPassengersConfig();
  const searchQueryWithLocalStorage =
    useSearchQueryParamsWithLocalStorage(passengerRules);

  const searchQueryParams = useSearchQueryParams(passengerRules);
  const searchQuery = shouldUseLocalStorage
    ? searchQueryWithLocalStorage
    : searchQueryParams;

  const [activeTrip, setActiveTrip] = useState<Trip>('round-trip');

  const [searchState, setSearchState] = useState<SearchState>('idle');

  const [stations, setStations] = useState<Stations>({
    origin: null,
    destination: null,
  });

  const { push, query } = usePartnerRouter();
  const { currency, residency } = useSettings();
  const originInputRef = useRef<HTMLInputElement>(null);
  const destinationInputRef = useRef<HTMLInputElement>(null);
  const { isHandheld: isMobile } = useIsHandheld();
  const {
    destinationStations,
    isDestinationStationsLoading,
    isOriginStationsLoading,
    originStations,
  } = useGetStations({
    origins: stations.origin ? getStationCodes(stations.origin) : undefined,
    destinations: stations.destination
      ? getStationCodes(stations.destination)
      : undefined,
  });

  const [isCollapsedSearchOpen, toggleCollapsedSearch] = useToggle();

  const destinationListNodes = arrangeStationsToListNodes(destinationStations);
  const originListNodes = arrangeStationsToListNodes(originStations);

  const [passengers, setPassengers] = useState<Passengers>(
    passengerRules.rules.reduce((prev, curr) => {
      // Set values as either:
      // The stored value for this passenger type (from the search url or local storage)
      // or the min count according to the pax rules.
      const paxType = searchQuery.paxTypeAges[curr.passengerType];

      return {
        ...prev,
        [curr.passengerType]: paxType ?? [curr.minCount ?? 0],
      };
    }, {} as Passengers),
  );

  // Find origin station list node based on the search query parameters
  const searchQueryOrigin = useMemo(
    () =>
      findStationListNodeBySearchQuery({
        items: originListNodes,
        queryCodes: searchQuery.origins,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchQuery.origins.join(','), originListNodes],
  );

  // Find destination station list node based on the search query parameters
  const searchQueryDestination = useMemo(
    () =>
      findStationListNodeBySearchQuery({
        items: destinationListNodes,
        queryCodes: searchQuery.destinations,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchQuery.destinations.join(','), destinationListNodes],
  );

  const [dateRange, setDateRange] = useState<DateRange>({
    from: searchQuery.departureDate,
    to: searchQuery.returnDate,
  });

  // If search query origin stations arrives client side, update the default values
  useEffect(() => {
    if (searchQueryOrigin && !stations.origin) {
      setStations({
        origin: searchQueryOrigin,
        destination: stations.destination,
      });
    }

    if (searchQuery.isOneWay) {
      setActiveTrip('one-way');
    }
  }, [
    searchQueryOrigin,
    searchQuery.isOneWay,
    stations.destination,
    stations.origin,
  ]);

  // If search query destination station arrives client side, update the default value
  useEffect(() => {
    if (searchQueryDestination && !stations.destination) {
      setStations({
        origin: stations.origin,
        destination: searchQueryDestination,
      });
    }
  }, [searchQueryDestination, stations.destination, stations.origin]);

  const searchQueryDepartureStringValue = searchQuery.departureDate?.toString();
  const searchQueryReturnStringValue = searchQuery.returnDate?.toString();

  // If search query dates arrive client side, update the default values
  // This can happen with legacy deeplinks
  useEffect(() => {
    if (
      (searchQuery.departureDate && !dateRange.from) ||
      (searchQuery.returnDate && !dateRange.to)
    ) {
      const from = dateRange.from ?? searchQuery.departureDate;
      const to = dateRange.to ?? searchQuery.returnDate;

      setDateRange({
        from,
        to,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQueryDepartureStringValue, searchQueryReturnStringValue]);

  const handleSetTrip = (type: Trip) => {
    setActiveTrip(type);

    // Save to local storage
    localStorage.setItem(SearchQueryParams.IS_ONEWAY, `${type === 'one-way'}`);
  };

  const handleOriginSelection = (item: StationListNode | null | undefined) => {
    // On mobile; close the window
    // On desktop; focus the next input
    if (isMobile) {
      setSearchState('idle');
    } else {
      destinationInputRef.current?.focus();
    }

    setStations({
      ...stations,
      origin: item ?? null,
    });

    // Save to local storage
    if (item) {
      localStorage.setItem(SearchQueryParams.ORIGINS, item.code);
    }
  };

  const handleDestinationSelection = (
    item: StationListNode | null | undefined,
  ) => {
    // On mobile; close the window
    // On desktop; focus the next input
    if (isMobile) {
      setSearchState('idle');
    } else {
      setSearchState('selecting-departure-date');
    }

    setStations({
      ...stations,
      destination: item ?? null,
    });

    // Save to local storage
    if (item) {
      localStorage.setItem(SearchQueryParams.DESTINATIONS, item.code);
    }
  };

  const handleSwitchStations = () => {
    setStations({
      origin: stations.destination,
      destination: stations.origin,
    });

    // Save to local storage
    if (stations.origin) {
      localStorage.setItem(
        SearchQueryParams.DESTINATIONS,
        stations.origin.code,
      );
    }

    if (stations.destination) {
      localStorage.setItem(
        SearchQueryParams.ORIGINS,
        stations.destination.code,
      );
    }
  };

  const handleSetDateRange = (newRange: DateRange) => {
    setDateRange(newRange);

    const shouldClose = detectCloseActionRequired({
      isOneWay: activeTrip === 'one-way',
      newFrom: newRange.from,
      newTo: newRange.to,
      to: dateRange.to,
    });

    if (shouldClose && isMobile) {
      setSearchState('idle');
    } else if (shouldClose) {
      setSearchState('selecting-passengers');
    } else if (newRange.from && !newRange.to) {
      setSearchState('selecting-arrival-date');
    }

    // We could save the date range to local storage here
  };

  const handleSetSearchState = (state: SearchState) => setSearchState(state);

  const handleSetPassengers = (passengers: Passengers) => {
    setPassengers(passengers);

    // We could save the passengers to local storage here
  };

  const handleSearchSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!stations.origin) {
      setSearchState('selecting-take-off-station');
      originInputRef.current?.focus();

      return;
    }

    if (!stations.destination) {
      setSearchState('selecting-destination-station');
      destinationInputRef.current?.focus();

      return;
    }

    if (!dateRange.from) {
      setSearchState('selecting-departure-date');

      return;
    }

    setSearchState('routing');

    const url = constructSearchResultQueryPushParams({
      origins: constructStationQueryParameters({
        originalItems: originStations,
        code: stations.origin.code,
      }),
      destinations: constructStationQueryParameters({
        originalItems: destinationStations,
        code: stations.destination.code,
      }),
      departureDate: dateRange.from,
      returnDate: dateRange.to ?? null,
      isOneWay: activeTrip === 'one-way',
      currency,
      residency,
      paxTypeAges: passengers,
      utmSource: transformUTMParam(query.utm_source),
      utmMedium: transformUTMParam(query.utm_medium),
      utmCampaign: transformUTMParam(query.utm_campaign),
    });

    await push({
      pathname: url.pathname,
      query: url.query,
      shallow: true,
    });

    setSearchState('idle');
  };

  return (
    <context.Provider
      value={{
        activeTrip,
        handleSetTrip,
        searchState,
        handleSetSearchState,
        passengers,
        handleSetPassengers,
        stations,
        dateRange,
        handleSetDateRange,
        handleSwitchStations,
        handleDestinationSelection,
        handleOriginSelection,
        handleSearchSubmit,
        originListNodes,
        destinationInputRef,
        originInputRef,
        destinationListNodes,
        isMobile,
        isDestinationStationsLoading,
        isOriginStationsLoading,
        isCollapsedSearchOpen,
        toggleCollapsedSearch,
        originStations,
        destinationStations,
      }}
    >
      {children}
    </context.Provider>
  );
};

export const useSearchWidget = () => useContext(context);
